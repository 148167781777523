/*--------------------------------------------------------------------------------------//
//                                       App.css                                        //
//                    Manage styles that cover the entire app here.                     //
//--------------------------------------------------------------------------------------*/


/* Fonts */
@font-face { 
  font-family: "Gilroy";
  src: url('fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url('fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url('fonts/Gilroy-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url('fonts/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url('fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face { /* Thasadith: For Skills <p> */
  font-family: "Thasadith";
  src: url('fonts/Thasadith.ttf') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face { /* SF Pro Text Light: For all <p> */
  font-family: "SF Pro Text Light";
  src: url('fonts/SF-Pro-Text-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face { /* SF Pro Display: For all Section Headers */
  font-family: "SF Pro Display";
  src: url('fonts/SF-Pro-Display.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face { /* SF Pro Display - Bold: For Home Header */
  font-family: "SF Pro Display";
  src: url('fonts/SF-Pro-Display-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}
@font-face { /* SF Pro Display - Italic (Regular): Currently unused, but italics version of most common font */
  font-family: "SF Pro Display";
  src: url('fonts/SF-Pro-Display-RegularItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}
@font-face { /* SF Pro Display - Ultra Light: For Section Headers, Home Alias */
  font-family: "SF Pro Display";
  src: url('fonts/SF-Pro-Display-Ultralight.woff2') format('woff2');
  font-weight: 300; 
  font-style: normal;
}
@font-face { /* Sam: Custom font for About Polaroid */
  font-family: 'Sam', 'Comic Sans MS', cursive; /* Adding Comic Sans MS as a fallback */
  src: url('fonts/Sam.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

/* Root - Variables to be reused for all sections */
:root {
  --section-margin: clamp(.75rem, 2.7vw, 1.6rem) clamp(0.6rem, 2vw, 10.2rem); 
}

/* All */
*, *:before, *:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;         /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;            /* Firefox, other Gecko */
  box-sizing: border-box;                 /* Opera/IE 8+ */
  transition: background-color 1s ease, 
  background-image 1s ease, 
  border 1s ease,
  border-bottom 1s ease; /* Transition for dark mode */
}
  *.dark-mode {
    transition: background-color 1s ease, 
    background-image 1s ease,
    border 1s ease,
    border-bottom 1s ease; /* Transition to go back to light mode */
  }


/* HTML of entire app */
html {
  font-size: clamp(14px, 4vw, 18px); 
  scroll-behavior: smooth;
}

/* Body of entire app */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

/* App - Wraps all content*/
.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

/* Main - Container for all content except nav bar */
main {
  width: 100%;
  height: 100%; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Sections - Containers for Home, Skills, About, Projects, Resume, Contact */
section {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

/* Headers for all sections */
h1,h2 {
  font-family: "SF Pro Display";
  font-weight: normal;
  letter-spacing: .1rem;
  font-size: clamp(1.8rem, 4.4vw, 2.9rem);
  margin-top: clamp(1.5rem, 4.7vw, 3.4rem);
  margin-bottom: clamp(1.5rem, 4.7vw, 3.4rem);
}

/* Paragraphs for all sections */
p {
  font-family: "SF Pro Text Light";
  font-weight: normal;
}

/* Animate Header - Animation for headers for sections */
.animate-header {
  animation: baseFadeInSlideDown 0.85s ease-out forwards;
  opacity: 1;
}

/* Section Headers - Headers for each section */
.section-header {
  margin-bottom: 0;
}

/* Section Subheaders - Little descriptions of sections below main header title */
.section-subheader {
  font-family: "SF Pro Display";
  font-weight: 300;
  align-self: center;
  text-align: center;
  margin-top: 0.4rem;
  margin-bottom: clamp(1.4rem, 4.4vw, 2.5rem);
  font-size: clamp(0.60rem, 2.4vw, 1.1rem);
  padding: 0 1rem;
  opacity: 0; /* Start off invisible before animation */
}
  .section-subheader a {
      display: flex;
      text-decoration: none;
      font-weight: 300;
  }  

/* Animations */
@keyframes baseFadeInSlideDown {
  from {
      opacity: 0;
      transform: translateY(-0.55rem); /* Start from the original position */
  }
  to {
      opacity: 1;
      transform: translateY(0%);
  }
}
@keyframes baseFadeInSlideRight {
  from {
      opacity: 0;
      transform: translateX(-1rem); /* Start from the original position */
  }
  to {
      opacity: 1;
      transform: translateX(0%); 
  }
}
@keyframes baseFadeInSlideLeft {
  from {
      opacity: 0;
      transform: translateX(1rem); /* Start from the original position */
  }
  to {
      opacity: 1;
      transform: translateX(0%);
  }
}

/* On wide screens, show the quote author */
@media (max-width: 950px) {
  .quote-author {
      display: none;
  }
}