/*--------------------------------------------------------------------------------------//
//                                      About.css                                        //
//--------------------------------------------------------------------------------------*/

/* About Section - Main container for the about section */
.about {
    display: flex;
    flex-direction: row;
    height: clamp(310px, 64vw, 650px);
    justify-content: center;
    align-items: center;
    min-height: 260px;
    max-height: 650px;
    gap: 2rem;
}

/* Header - Title for About section */
.about__header {
    font-family: 'Gilroy', sans-serif;
    font-weight: 600;
    color: var(--app-theme-color);
    font-size: clamp(0.6rem, 2vw, 1rem);
}

/* Subtitle - Subtitle for About section */
.about__subheader {
    font-family: 'Gilroy', sans-serif;
    margin: clamp(0.3rem, 1vw, 1rem) 0;
    font-weight: 600;
    letter-spacing: -2px;
    font-size: clamp(1.3rem, 4vw, 2.6rem);
}

/* Left Side - Left part of the about page with picture and title */
.about__left {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    margin-left: 9vw;
    width: 40%;
    height: 100%;
    opacity: 0;
}

.animate-about__left {
    animation: baseFadeInSlideRight .7s ease-in-out;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about p {
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
    font-size: clamp(0.6rem, 2vw, 1rem);

    display: block;
    padding-right: clamp(0rem, 5vw, 6rem);
}

.about__image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1/1;
    width: 100%;
    max-width: 350px;
    box-shadow: clamp(2px, 0.5vw, 5px) clamp(2px, 0.5vw, 5px) clamp(2px, 0.5vw, 4px) 0 rgba(0, 0, 0, 0.2);
    border-right: 5px solid var(--app-theme-color);
    border-bottom: 3px solid var(--app-theme-color);
    border-radius: 50%;
    overflow: hidden;
}

.about img {
    width: 92%; /* Increases the size of the image */
}

/* Right Side - Right side of the about page with text */
.about__right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    width: 60%;
    margin-right: 9vw;
    font-size: clamp(0.6rem, 2vw, 1.45rem);
    z-index: 0;
    opacity: 0;
}
.animate-about__right {
    animation: baseFadeInSlideLeft .7s ease-in-out;
    opacity: 1;
}
.about .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    gap: clamp(10px, 2vw, 20px);

}

@media (max-width: 412px) {
    .about {
        height: 330px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0;
    }
    .about__left {
        display: none;
        width: clamp(120px, 70vw, 170px);
        padding-top: 20px;
        margin: 0;
        justify-content: center;
    }
    .about__right {
        width: 100%;
        margin: 0;
        padding: 0 20px;
    }
    .about__subheader {
        
        font-size: clamp(1.3rem, 9vw, 2rem);
        margin: 10px 0;
    }
    .about p {
        font-size: clamp(0.8rem, 4.3vw, 1rem);
        margin: 0;
    }
}